import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";

class FaqPage extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <h3>FAQs</h3>

          <h4>Q. 有怎樣的運送方式呢?</h4>
          <p>
            我們提供超商取貨、郵寄與宅配的服務。您可於訂單完成後，選擇最適合您的收件方式。
          </p>

          <h4>Q. 使用信用卡付款安全嗎?</h4>
          <p>
            我們是使用綠界信用卡付款系統。客戶經由綠界付款，我們不會保留客戶的信用卡資料。
          </p>

          <h4>Q. 請問天數如何計算?</h4>
          <p>
            天數計算是以台灣時間(GMT+8)
            00:00:00至23:59:59為一天。不足一天亦以一天計算。
          </p>

          <h4>Q. 請問手機型號影響嗎?</h4>
          <p>一般有支援4G的手機都能使用。</p>

          <h4>Q 有提供什麼大小SIM卡?</h4>
          <p>
            我們提供三合一SIM卡，有NANO，MICRO及NORMAL SIM，適用於大部份的手機。
          </p>

          <h4>Q. 卡片需要設定嗎?</h4>
          <p>我們的卡片不用額外設定，插卡後開啟數據漫遊，就能連結網路。</p>

          <h4>Q. 手機需要開啟漫遊功能，會否產生漫遊費用?</h4>
          <p>
            我們是數據漫遊卡，在插入SIM卡後需要開啟數據漫遊功能才能使用上網服務，當中不會產生任何漫遊費用。每次使用完我們的SIM卡，或換上其他SIM卡時，請記得關閉數據漫遊功能，以免產生不必要的漫遊費用。
          </p>

          <h4>Q. 目前客製化上網卡可在哪些國家使用?</h4>
          <p>
            我們目前在亞洲與歐美地區地區提供服務，未來將會繼續增加新的國家或地區。
          </p>

          <h4>Q. 請問有支援語音通話及簡訊服務嗎?</h4>
          <p>我們只提供流動數據服務，並不支援通話與簡訊服務。</p>

          <h4>Q. 支援熱點分享嗎?</h4>
          <p>
            可以使用手機熱點分享，但無法於電腦或WIFI機上使用。遵照公平使用原則，一般建議維持在2人使用。
          </p>

          <h4>Q. 數據流量有限制嗎？</h4>
          <p>
            我們提供無限數據，但請留意公平使用原則，建議您於需要高流量時盡量使用免費WIFI。
          </p>

          <h4>Q. 卡片是否可以退換貨?</h4>
          <ul>
            <li>
              根據消費者保護法第19條，「客製化商品」不適用七天鑑賞期間（猶豫期）規定，一旦下單開始製作，恕不接受取消訂單或是退貨，下單前請務必慎重考量自身需求。
            </li>
            <li>
              若因商品有瑕疵或寄送錯誤等因素需辦理退/換貨時，請您於7日內直接辦理退/換貨，運費將由二十五電訊全額負擔。
            </li>
            <li>
              如有未盡事宜，二十五電訊將保留修改、終止、變更內容細節之權利。
            </li>
          </ul>

          <h4>Q. 如果我在使用上有任何技術上問題，可以怎樣解決？</h4>
          <p>
            您可以加入我們的LINE@線上客服：@228uhcqp，我們會有專人為您解決問題。
          </p>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px 0;
  background-color: #ededed;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 50px;

  & > h3 {
    padding: 20px 0px;
    color: #5a5a5a;
  }

  & > h4 {
    padding: 10px 0px;
    color: #5a5a5a;
  }

  & > p,
  li {
    color: #6b6b6b;
    font-size: 15px;
    line-height: 30px;
    list-style-position: inside;
  }
`;

export default withPage(FaqPage);
